import React from "react";
import { CandlestickData, createChart, CrosshairMode, ISeriesApi, WhitespaceData } from 'lightweight-charts';
import isEqual from 'lodash/isEqual';
import { Period } from "src/types/period";

import style from "./Graph.module.scss";

interface IProps {
  period: Period,
  data: Array<CandlestickData | WhitespaceData>
}

export default class Graph extends React.Component<IProps, any> {
  private containerRef = React.createRef<HTMLDivElement>();
  private chartRef: any = React.createRef();
  private resizeObserver: any = React.createRef();

  private candleSeries!: ISeriesApi<"Candlestick">;
  private volumeSeries!: ISeriesApi<"Histogram">;

  componentDidMount() {
    const { data } = this.props;

    const container = this.containerRef.current!!;

    const { clientWidth, clientHeight } = container;

    this.chartRef.current = createChart(container, {
      width: clientWidth,
      height: clientHeight > 400 ? clientHeight : 400,
      layout: {
        backgroundColor: '#000000',
        textColor: '#939495'
      },
      grid: {
        vertLines: {
          color: '#17191F'
        },
        horzLines: {
          color: '#17191F'
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal
      },
      rightPriceScale: {
        borderColor: '#1B1B1B'
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderColor: '#1B1B1B',
        barSpacing: 3,
        tickMarkFormatter: (time: any, markType: any) => {
          const { period } = this.props;

          let options;
          switch(period) {
            case Period.M1:
            case Period.M5:
            case Period.M10:
            case Period.H1:
              options = { hour: "2-digit", minute: '2-digit' };
              break;
            case Period.D1:
              options = { day: '2-digit', month: 'short' }
              break;
            default:
              return time;
          }

          // @ts-ignore
          return new Date(time * 1000).toLocaleString("en-US", options)
        }
      },
    });

    const chart = this.chartRef.current;

    this.candleSeries = chart.addCandlestickSeries({
      wickVisible: true,
      borderVisible: false,
      upColor: "#21E7B6",
      downColor: "#EF5252",
      wickUpColor: '#21E7B6',
      wickDownColor: '#EF5252'
    });

    this.candleSeries.setData(data);

    this.volumeSeries = chart.addHistogramSeries({
      color: '#ACACAE',
      priceFormat: {
        type: 'volume',
      },
      overlay: true,
      scaleMargins: {
        top: 0.8,
        bottom: 0
      },
      priceLineVisible: false,
      baseLineVisible: false,
      lastValueVisible: false
    });

    this.volumeSeries.setData(data);

    this.resize();
  }

  shouldComponentUpdate(
    nextProps: Readonly<IProps>,
    nextState: Readonly<any>,
    nextContext: any
  ): boolean {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {

    let { period, data } = this.props;

    if (prevProps.period === period) {
      this.resizeObserver.current?.disconnect();
    }

    if (prevProps.data !== data) {

      this.candleSeries.setData(data);
      this.volumeSeries.setData(data.map((element) => {
        // @ts-ignore
        element.value = element.volume;
        return element;
      }));

    }

    if (prevProps.period !== period) {
      this.resize();
    }

  }

  componentWillUnmount() {
    this.resizeObserver.current.disconnect();
  }

  resize() {
    this.resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      this.chartRef.current.applyOptions({ width, height });
      setTimeout(() => {
        this.chartRef.current.timeScale().fitContent();
      }, 0);
    });

    this.resizeObserver.current.observe(this.containerRef.current);
  }

  render() {
    return <div className={style.graph} ref={this.containerRef} />;
  }
}
