import React from "react";
import axios from "axios";

import { ConstantBackoff, Websocket, WebsocketBuilder } from 'websocket-ts';

import Header from "src/components/Header";
import Tabbar from "src/components/Tabbar";

import Chart  from "src/components/Graph";

import AvgItem, { IAvgItem } from "src/components/AvgItem";

import ArrowUp from "src/assets/icons/arrow-up.svg";
import ArrowDown from "src/assets/icons/arrow-down.svg";
import Withdraw from "src/assets/icons/withdraw.svg";
import Deposit from "src/assets/icons/deposit.svg";
import Light from "src/assets/icons/light.svg";
import Chevron from "src/assets/icons/chevron.svg";
import Close from "src/assets/icons/close.svg";

import Plus from "src/assets/icons/plus.svg";
import Settings from "src/assets/icons/settings.svg";

import { classNames } from "src/functions/classNames";

import { pairLocale } from "src/data/pairLocale";
import { periodLocale } from "src/data/periodLocale";
import { BASE_URL, WS_BASE_URL } from "src/data/config";

import { Pair } from "src/types/pair";
import { Period } from "src/types/period";

import { CandlestickData, WhitespaceData } from "lightweight-charts";

import style from "./index.module.scss";

interface IProps {}

interface IState {
  graphOpen: boolean,
  configuration: {
    pairs: Array<{
      from: Pair,
      to: Pair
    }>,
    periods: Array<Period>
  } | null,
  currentPair: {
    from: Pair,
    to: Pair
  },
  currentPeriod: Period,
  openPeriodSelection: boolean,
  openPairSelection: boolean,
  avgList: {
    [key: string]: IAvgItem
  } | null,
  graphData: Array<CandlestickData | WhitespaceData> | null
}

export default class IndexPage extends React.Component<IProps, IState> {

  private ws!: Websocket;

  constructor(props: IProps) {
    super(props);

    this.state = {
      graphOpen: false,
      configuration: null,
      currentPair: {
        from: Pair.BTC,
        to: Pair.BUSD
      },
      currentPeriod: Period.M1,
      openPeriodSelection: false,
      openPairSelection: false,
      avgList: null,
      graphData: null
    };

    this.handleItemClose = this.handleItemClose.bind(this);
  }

  componentDidMount() {
    return Promise.all([
      this.getConfig(),
      this.connectWs()
    ]);
  }

  async getConfig() {
    const { data } = await axios.get(`${BASE_URL}/config`);

    this.setState({
      configuration: data
    });
  }

  async connectWs() {
    this.ws = new WebsocketBuilder(`${WS_BASE_URL}/feed`)
      .onMessage((ws, msg) => {
        const data = JSON.parse(msg.data);

        if (data.type === "AVG_PRICE") {
          this.setState({
            avgList: data.payload
          });
        }

        if (data.type === "GRAPH_DATA") {

          let content = data.payload.content;
          let filteredData = content.filter((element: any) => {
            return content.filter((other: any) => element.time === other.time).length === 1
          });

          this.setState({
            graphData: filteredData
          });
        }
      })
      .onOpen((ws) => ws.send(JSON.stringify({
        type: "CHANGE_GRAPH_STATE",
        payload: {
          visible: true /* Вкл./Выкл. получение данных для графика */,
          page: 0 /* Страница для пагинации 0 - n (дефолт 0) */,
          pageSize: 1000 /* Кол-во записей на странице (дефолт 100) */
        }
      })))
      .withBackoff(new ConstantBackoff(5000))
      .build();
  }

  handleItemClose() {
    this.setState({
      graphOpen: false
    });
  }

  changePair(from: Pair, to: Pair) {
    this.ws.send(JSON.stringify({
      type: "CHANGE_PAIR",
      payload: {
        from,
        to
      }
    }));

    this.setState({
      openPairSelection: false,
      graphOpen: true,
      currentPair: {
        from,
        to
      }
    });
  }

  changePeriod(period?: Period) {
    const { openPeriodSelection, currentPeriod } = this.state;

    if (period !== currentPeriod) {
      this.ws.send(JSON.stringify({
        type: "CHANGE_PERIOD",
        payload: {
          period
        }
      }));
    }

    this.setState({
      openPeriodSelection: !openPeriodSelection,
      currentPeriod: period !== undefined ? period : currentPeriod
    });
  }

  handlePairSelection() {
    const { openPairSelection } = this.state;

    this.setState({
      openPairSelection: !openPairSelection
    });
  }

  render() {
    const {
      graphOpen,
      currentPair,
      currentPeriod,
      openPeriodSelection,
      openPairSelection,
      avgList,
      configuration,
      graphData
    } = this.state;

    return (
      <div className={style.page}>
        <Header onLogoClick={() => this.setState({ graphOpen: false })} />
        <div className={style.balance}>
          <div className={style.info}>
            <div className={style.infoTitle}>
              <span>$ 4,249.94</span>
              <div className={style.status}>
                <img src={ArrowUp} alt=""/>
                <span>1.3%</span>
              </div>
            </div>
            <div className={style.infoDescription}>
              <div>4.41942 BNB</div>
              <div>0.0441942 ETH</div>
            </div>
          </div>
          <div className={style.actions}>
            <div className={style.actionsItem}>
              <img src={Deposit} alt=""/>
            </div>
            <div className={style.actionsItem}>
              <img src={Withdraw} alt=""/>
            </div>
          </div>
        </div>
        <div className={style.period}>
          <div className={style.info} onClick={() => this.changePeriod()}>
            <div className={style.infoCaption}>Period</div>
            <div className={style.infoTitle}>
              <span>{periodLocale[currentPeriod]}</span>
              <img src={Chevron} alt=""/>
            </div>
          </div>
          <div className={classNames(style.selection, openPeriodSelection && style.selectionActive)}>
            {configuration && configuration.periods.map((period) => (
              <div
                className={style.selectionItem}
                key={period}
                onClick={() => this.changePeriod(period)}
              >
                {periodLocale[period]}
              </div>
            ))}
          </div>
        </div>
        {!graphOpen ? (
          <>
            <div className={style.portfolio}>
              <div className={style.info}>
                <div className={style.infoCaption}>Portfolio</div>
                <div className={style.infoBalance}>
                  <span>$ 10,249.94</span>
                  <div className={style.status}>
                    <img src={ArrowDown} alt=""/>
                    <span>2.49%</span>
                  </div>
                </div>
              </div>
              <div className={style.action}>
                <img src={Light} alt=""/>
              </div>
            </div>
            {avgList && configuration && (
              <div className={style.list}>
                {configuration.pairs.map((item, index) => (
                  <AvgItem
                    key={index}
                    avg={item.from}
                    data={avgList[item.from + item.to]}
                    handleItemClick={() => this.changePair(item.from, item.to)}
                  />
                ))}
              </div>
            )}
            <Tabbar firstItem="Deposit" secondItem="Withdraw" icon={Plus} />
          </>
        ) : (
          <>
            <div className={style.transfer}>
              <div className={style.transferInner}>
                <div className={style.info} onClick={() => this.handlePairSelection()}>
                  <div className={style.infoCaption}>{pairLocale[currentPair.from]} - {pairLocale[currentPair.to]}</div>
                  <div className={style.infoTitle}>
                    <span>{currentPair.from}/{currentPair.to}</span>
                    <img src={Chevron} alt=""/>
                  </div>
                </div>
                <div className={style.close} onClick={this.handleItemClose}>
                  <img src={Close} alt=""/>
                </div>
              </div>
              <div className={classNames(style.selection, openPairSelection && style.selectionActive)}>
                {configuration && configuration.pairs.map((pair, index) => (
                  <div
                    className={style.selectionItem}
                    key={index}
                    onClick={() => this.changePair(pair.from, pair.to)}
                  >
                    {pair.from}/{pair.to}
                  </div>
                ))}
              </div>
            </div>
            {graphData && <Chart period={currentPeriod} data={graphData} />}
            <Tabbar firstItem="Buy AMC" secondItem="Sell AMC" icon={Settings} />
          </>
        )}
      </div>
    );
  }

}
