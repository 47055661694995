import React from "react";

import { classNames } from "src/functions/classNames";

import style from "./index.module.scss";

interface IProps {
  onLogoClick(): any
}

interface IState {
  isBurger: boolean
}

export default class Header extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      isBurger: false
    };

    this.handleBurgerClick = this.handleBurgerClick.bind(this);
  }

  handleBurgerClick() {
    const { isBurger } = this.state;

    this.setState({
      isBurger: !isBurger
    });
  }

  render() {
    const { onLogoClick } = this.props;
    const { isBurger } = this.state;

    return (
      <header className={classNames(style.header, isBurger && style.headerActive)}>
        <div className={style.menu}>
          <div className={style.title} onClick={onLogoClick}>Swapper</div>
          <div className={style.burger} onClick={this.handleBurgerClick}>
            <div className={style.line} />
            <div className={style.line} />
            <div className={style.line} />
          </div>
        </div>
        <div className={classNames(style.headerInner, isBurger && style.headerInnerActive)}>
          Test content
        </div>
      </header>
    );
  }

}
