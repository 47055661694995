import React from "react";

import Withdraw from "src/assets/icons/withdraw.svg";
import Deposit from "src/assets/icons/deposit.svg";

import style from "./index.module.scss";

interface IProps {
  firstItem: string,
  secondItem: string,
  icon: string
}

export default class Tabbar extends React.Component<IProps, any> {

  render() {
    const { firstItem, secondItem, icon } = this.props;

    return (
      <div className={style.tabbar}>
        <div className={style.menu}>
          <div className={style.menuItem}>
            <img src={Deposit} alt=""/>
            <span>{firstItem}</span>
          </div>
          <div className={style.menuItem}>
            <img src={Withdraw} alt=""/>
            <span>{secondItem}</span>
          </div>
        </div>
        <div className={style.action}>
          <img src={icon} alt=""/>
        </div>
      </div>
    );
  }

}
