import React from 'react';
import ReactDOM from 'react-dom/client';

import IndexPage from "src/pages";

import './style/index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function App() {
  return (
    <IndexPage />
  );
}

root.render(<App />);
