import React from "react";
import CountUp from "react-countup";
import { pairLocale } from "src/data/pairLocale";

import { classNames } from "src/functions/classNames";

import ArrowUp from "src/assets/icons/arrow-up.svg";
import ArrowDown from "src/assets/icons/arrow-down.svg";

import Bitcoin from "src/assets/currency/bitcoin.svg";
import Etherium from "src/assets/currency/etherium.svg";
import Bnb from "src/assets/currency/bnb.png";
import { Pair } from "src/types/pair";

import style from "./index.module.scss";

export interface IAvgItem {
  price: number,
  change: number,
  changePercent: number
}

interface IProps {
  avg: Pair,
  data: IAvgItem,
  handleItemClick(): void
}

export default class AvgItem extends React.Component<IProps> {

  getIcon() {
    const { avg } = this.props;

    const icons = {
      [Pair.BTC]: Bitcoin,
      [Pair.ETH]: Etherium,
      [Pair.BNB]: Bnb,
      [Pair.BUSD]: Bnb
    };

    return icons[avg];
  }

  getLocale() {
    const { avg } = this.props;

    return pairLocale[avg];
  }

  render() {
    const { avg, data, handleItemClick } = this.props;

    const icon = this.getIcon();
    const locale = this.getLocale();

    const isUp = data.changePercent >= 0;

    return (
      <div className={style.listItem} onClick={handleItemClick}>
        <img className={style.logo} src={icon} alt="" />
        <div className={style.info}>
          <div className={style.infoCaption}>{locale}</div>
          <div className={style.infoBalance}>
            <CountUp
              end={data.price}
              prefix="$ "
              decimals={2}
              separator=","
              decimal="."
              duration={0.5}
            />
            <div className={classNames(style.status, isUp ? style.statusUp : style.statusDown)}>
              <img src={isUp ? ArrowUp : ArrowDown} alt=""/>
              <CountUp
                end={data.changePercent}
                decimals={2}
                decimal=","
                suffix="%"
                duration={0.5}
              />
            </div>
          </div>
          <div className={style.infoDescription}>
            <CountUp
              end={Math.abs(data.change)}
              decimals={2}
              decimal=","
              duration={0.5} />
            {avg}
          </div>

        </div>
      </div>
    );
  }

}
